import React from 'react';

import * as styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Privacy Policy</h1>

      <div className={styles.section}>
        Questo documento sulla Privacy Policy contiene i tipi di informazioni
        che vengono raccolte e registrate da Tiziano Bieller Sport sul sito{' '}
        <strong>www.tizianobiellersport.com</strong> e come le utilizziamo.
        <br />
        Se hai ulteriori domande o necessiti di maggiori informazioni sulla
        nostra Informativa sulla privacy, non esitare a contattarci.
      </div>

      <div className={styles.section}>
        La presente Informativa sulla privacy si applica solo alle nostre
        attività online ed è valida per i visitatori del nostro sito Web in
        relazione alle informazioni che hanno condiviso e/o raccolto in Tiziano
        Bieller Sport. Questa politica non è applicabile alle informazioni
        raccolte offline o tramite canali diversi da questo sito web. La nostra
        politica sulla privacy è stata creata con l'aiuto del generatore di
        norme sulla privacy gratuito.
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>Consenso</div>
        Utilizzando il nostro sito Web, acconsenti alla nostra Informativa sulla
        privacy e accetti i suoi termini.
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>Informazioni che raccogliamo</div>
        Le informazioni personali che ti viene chiesto di fornire e i motivi per
        cui ti viene chiesto di fornirle ti saranno chiarite nel momento in cui
        ti chiediamo di fornire le tue informazioni personali.
        <br />
        Se ci contatti direttamente, potremmo ricevere informazioni aggiuntive
        su di te come nome, indirizzo e-mail, numero di telefono, contenuto del
        messaggio e/o allegati che potresti inviarci e qualsiasi altra
        informazione tu possa scegliere di fornire.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Come utilizziamo le tue informazioni
        </div>
        Utilizziamo le informazioni che raccogliamo in vari modi, incluso per
        fornire, gestire e mantenere il nostro sito web e l'attività fisica sul
        territorio
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Informativa sulla privacy dei partner pubblicitari
        </div>
        Puoi consultare questo elenco per trovare la Privacy Policy di ciascuno
        dei partner pubblicitari di Tiziano Bieller Sport. Gli ad server o le
        reti pubblicitarie di terze parti utilizzano tecnologie come cookie,
        JavaScript o Web Beacon utilizzati nei rispettivi annunci pubblicitari e
        collegamenti visualizzati su Tiziano Bieller Sport, che vengono inviati
        direttamente al browser degli utenti. Ricevono automaticamente il tuo
        indirizzo IP quando ciò si verifica. Queste tecnologie vengono
        utilizzate per misurare l'efficacia delle loro campagne pubblicitarie
        e/o per personalizzare i contenuti pubblicitari che vedi sui siti web
        che visiti. Si noti che Tiziano Bieller Sport non ha accesso o controllo
        su questi cookie utilizzati da inserzionisti di terze parti.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Politiche sulla privacy di terze parti
        </div>
        L'informativa sulla privacy di Tiziano Bieller Sport non si applica ad
        altri inserzionisti o siti web. Pertanto, ti consigliamo di consultare
        le rispettive Norme sulla privacy di questi ad server di terze parti per
        informazioni più dettagliate. Può includere le loro pratiche e
        istruzioni su come rinunciare a determinate opzioni. Puoi scegliere di
        disabilitare i cookie attraverso le singole opzioni del tuo browser. Per
        conoscere informazioni più dettagliate sulla gestione dei cookie con
        specifici browser web, è possibile trovarle nei rispettivi siti web dei
        browser.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Diritti sulla privacy CCPA (non vendere le mie informazioni personali)
        </div>
        Ai sensi del CCPA, tra gli altri diritti, i consumatori della California
        hanno il diritto di: Richiedere a un'azienda che raccoglie i dati
        personali di un consumatore di divulgare le categorie e le parti
        specifiche di dati personali che un'azienda ha raccolto sui consumatori.
        Richiedere a un'azienda di eliminare tutti i dati personali sul
        consumatore che un'azienda ha raccolto. Richiedere che un'azienda che
        vende i dati personali di un consumatore, non venda i dati personali del
        consumatore. Se fai una richiesta, abbiamo un mese per risponderti. Se
        desideri esercitare uno di questi diritti, contattaci.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Diritti di protezione dei dati GDPR
        </div>
        Vorremmo assicurarci che tu sia pienamente consapevole di tutti i tuoi
        diritti di protezione dei dati. Ogni utente ha diritto a: Il diritto di
        accesso – Hai il diritto di richiedere copie dei tuoi dati personali.
        Potremmo addebitarti una piccola commissione per questo servizio. Il
        diritto di rettifica – Hai il diritto di richiedere che correggiamo
        qualsiasi informazione che ritieni sia imprecisa. Hai anche il diritto
        di richiedere che completiamo le informazioni che ritieni incomplete.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Il diritto alla cancellazione</div>
        Hai il diritto di richiedere la cancellazione dei tuoi dati personali, a
        determinate condizioni.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Il diritto di limitare il trattamento
        </div>
        Hai il diritto di richiedere che limitiamo il trattamento dei tuoi dati
        personali, a determinate condizioni.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Il diritto di opporsi al trattamento
        </div>
        Hai il diritto di opporti al nostro trattamento dei tuoi dati personali,
        a determinate condizioni.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Il diritto alla portabilità dei dati
        </div>
        Hai il diritto di richiedere che trasferiamo i dati che abbiamo raccolto
        a un'altra organizzazione, o direttamente a te, a determinate
        condizioni.
      </div>

      <div className={styles.section}>
        Se fai una richiesta, abbiamo un mese per risponderti. Se desideri
        esercitare uno di questi diritti, contattaci.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Informazioni per bambini</div>
        Un'altra parte della nostra priorità è aggiungere la protezione dei
        bambini durante l'utilizzo di Internet. Incoraggiamo i genitori e i
        tutori a osservare, partecipare e/o monitorare e guidare la loro
        attività online.
        <br />
        Tiziano Bieller Sport non raccoglie consapevolmente alcuna informazione
        di identificazione personale da bambini di età inferiore ai 13 anni. Se
        ritieni che tuo figlio abbia fornito questo tipo di informazioni sul
        nostro sito Web, ti invitiamo vivamente a contattarci immediatamente e
        faremo del nostro meglio per rimuovere tempestivamente tali informazioni
        dai nostri archivi.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
