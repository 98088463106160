import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import PrivacyPolicy from '@components/PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyPage = ({ location }) => {
  return (
    <Layout location={location} map>
      <Seo title="Privacy Policy - Tiziano Bieller Sport" lang="it" />
      <PrivacyPolicy />
    </Layout>
  );
};

export default PrivacyPolicyPage;
